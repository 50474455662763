import React from 'react'
import { Title, Release, Attribution, ItemLink, ReleasedDate } from './styles'
import { Text } from 'rebass'
import { Link } from 'gatsby'
import moment from 'moment'
import Img from 'gatsby-image'

const ArtistRelease = (props) => {
  // const image = props.release_artwork
  const cleanedTitle = props.release.title.replace('&#038;', '&')
  const title = <Title is='h3' f={[3]} pt={23}>{ cleanedTitle }</Title>
  const formattedDate = moment(props.release.releasedate, 'DD/MM/YYYY').format('MMMM D, YYYY')
  return (
    <Release
      w={[1 / 1, null, 'auto']}
      pr={[0, null, 96]} mb={['2.5rem', null, '5rem']}
      small={props.small}
    >
      <Link to={props.release.link}>
        { props.release.sizes &&
          <Img src={props.release.sizes.src} sizes={props.release.sizes} />
        }
        { props.showreleasedate &&
          <ReleasedDate mt={3} fontSize={0}>Released {formattedDate}</ReleasedDate>
        }
        <Text textAlign={(props.showreleasedate) ? 'right' : 'left'}>
          { !props.showreleasedate &&
          props.release.title && title
          }
          { props.release.attribution &&
          <ItemLink to={props.release.attribution.link}>
            <Attribution f={[1]}>by <span className='highlight'>{ props.release.attribution.source }</span></Attribution>
          </ItemLink>
          }

        </Text>

      </Link>
    </Release>
  )
}

export default ArtistRelease
